import { configureStore } from '@reduxjs/toolkit';
import {
  PLATFORM_FEATURE_KEY,
  globalErrorHandlerMiddleware,
  platformReducers,
} from '@clatter/platform';

export const storeConfig = {
  reducer: {
    [PLATFORM_FEATURE_KEY]: platformReducers,
  },
  // Additional middleware can be passed to this array
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(globalErrorHandlerMiddleware),
  devTools: process.env.NODE_ENV !== 'production',
};

export const store = configureStore(storeConfig);
