import React, { useCallback, useState } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  eventRequestTypes,
  trackEvent,
  useAuth,
  useEffectOnce,
  useGetActiveTool,
  useMountedEffect,
} from '@clatter/platform';
import { Loader } from '@clatter/ui';
import LoginForm from './LoginForm';
import CountdownTimer from './CountdownTimer';
import PasswordRecoveryForm from './PasswordRecoveryForm';

export const EmailLoginScreen = ({ successfulLoginRedirectUrl }) => {
  const activeTool = useGetActiveTool();
  const navigate = useNavigate();
  const location = useLocation();
  const reduxDispatch = useDispatch();
  const { login, changePassword, isLoading } = useAuth();

  const [countdown, setCountDown] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [failedLoginAttempts, setFailedLoginAttempts] = useState(0);
  const [showPasswordResetMessage, setShowPasswordResetMessage] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const { redirectTo } = queryString.parse(location.search) || {};

  //region METHODS
  const handleErrorResponse = useCallback((error) => {
    switch (error.code) {
      case 'too_many_attempts':
        setErrorMessage(error.error_description);
        break;
      case 'access_denied':
        setErrorMessage('Incorrect email or password entered.');
        setFailedLoginAttempts((prev) => prev + 1);
        break;
      case 'unauthorized':
        setErrorMessage('Password reset required. Please check your inbox.');
        setFailedLoginAttempts((prev) => prev + 1);
        break;
      case 'blocked_user':
        setErrorMessage('Account blocked. Please contact your administrator.');
        setFailedLoginAttempts((prev) => prev + 1);
        break;
      default:
        setErrorMessage('Sorry there was an error. Try again or contact your administrator.');
    }
  }, []);

  const handleLogin = (data) => {
    return login(
      {
        username: data.email,
        password: data.password,
        redirectTo: redirectTo ? window.location.origin + `?redirectTo=${redirectTo}` : window.location.origin,
      },
      (error) => {
        if (!error) {
          return navigate(successfulLoginRedirectUrl); // this has no effect at all!!!!
        }

        // track failed login event
        reduxDispatch(
          trackEvent({
            type: eventRequestTypes.failedLogin,
            data: {
              tool:
                activeTool?.slug?.toUpperCase() ||
                (process.env.NX_TOOL_NAME !== 'PM' ? process.env.NX_TOOL_NAME : 'PG'),
              user_email: data.email,
              error: error.error,
              error_code: error.code,
              error_description: error.error_description,
            },
          }),
        );

        handleErrorResponse(error);
      },
    );
  };

  const handlePasswordReset = ({ email }) => {
    changePassword(email, (error) => {
      if (error) {
        handleErrorResponse(error);
      } else {
        setShowPasswordResetMessage(true);
      }
    });
  };

  const handleShowForgotPassword = () => {
    setErrorMessage(null);
    setShowForgotPassword((prev) => !prev);
  };
  //endregion

  //region EFFECTS
  useEffectOnce(() => {
    if (!window.location.search.includes('sso_error')) {
      return false;
    }

    return handleErrorResponse({ code: 'access_denied' });
  }, []);

  useMountedEffect(() => {
    if (!showForgotPassword) {
      setShowPasswordResetMessage(false);
    }
  }, [showForgotPassword]);

  useMountedEffect(() => {
    const attempts = 2 ** (failedLoginAttempts - 1);
    const timeout = attempts * 1000;
    let timer = null;

    if (attempts > 1) {
      setSubmitDisabled(true);
      setCountDown(timeout);

      timer = setTimeout(() => {
        setCountDown(0);
        setSubmitDisabled(false);
      }, timeout);
    }

    return () => clearTimeout(timer);
  }, [failedLoginAttempts]);
  //endregion

  return (
    <>
      {!showForgotPassword ? (
        <LoginForm
          onValidSubmit={handleLogin}
          onForgotPassword={handleShowForgotPassword}
          submitDisabled={submitDisabled}
          errorMessage={errorMessage}
          submitButtonContent={submitDisabled && <CountdownTimer miliseconds={countdown} />}
        />
      ) : (
        <PasswordRecoveryForm
          onValidSubmit={handlePasswordReset}
          onBackToLogin={handleShowForgotPassword}
          showMessage={showPasswordResetMessage}
        />
      )}
      {isLoading && <Loader />}
    </>
  );
};
