import React, { useEffect } from 'react';
import {
  matchPath,
  Route,
  Routes,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { appGlobalStyles, Analytics, AppThemeProvider, SsoCallbackPage, TrackPageviewEvent, LogoutPageView } from '@clatter/ui';
import {
  useAuth,
  fetchToolsConfig,
  fetchToolInfo,
  sharedRoutes,
  fetchFeatureFlag,
  fetchSystemProfile,
} from '@clatter/platform';
import { createGlobalStyle } from 'styled-components';
import routes from './routes';
import LandingPageView from './pages/LandingPage/LandingPageView';
import HomePageView from './pages/Home/HomePageView';

const App = () => {
  const GlobalStyle = createGlobalStyle`
    ${appGlobalStyles};

    // Apply global scrollbar styles if there is no .landing-page
    body:not(.landing-page) ::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  `;

  const dispatch = useDispatch();
  const { isAuthenticated, checkSession } = useAuth();
  const location = useLocation();

  const isOnLogoutPage = !!matchPath(routes.logout, location.pathname);
  const isOnSsoCallbackPage = !!matchPath(sharedRoutes.ssoCallback, location.pathname);

  useEffect(() => {
    dispatch(fetchToolInfo());
  }, [dispatch]);

  // set auth session after page refresh
  useEffect(() => {
    if (!isOnLogoutPage) {
      checkSession();
    }
  }, [checkSession]);

  useEffect(() => {
    if (isAuthenticated && !isOnLogoutPage && !isOnSsoCallbackPage) {
      dispatch(fetchFeatureFlag());
      dispatch(fetchToolsConfig());
      dispatch(fetchSystemProfile());
    }
  }, [dispatch, isAuthenticated]);

  return (
    <AppThemeProvider>
      <GlobalStyle />
        <TrackPageviewEvent />
        <Analytics />

        <Routes>
          <Route
            path={routes.index}
            element={isAuthenticated ? <HomePageView /> : <LandingPageView />}
          />
          <Route path={sharedRoutes.ssoCallback} element={<SsoCallbackPage />} />
          <Route path={routes.logout} element={<LogoutPageView />} />
          <Route path="*" element={<Navigate to={routes.index} />} />
        </Routes>
    </AppThemeProvider>
  );
};
export default App;
